import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { initCollapses, initDropdowns, initFlowbite } from 'flowbite';
import { CommonModule, isPlatformBrowser, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { ToastComponent } from './_components/toast/toast.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, CommonModule, ToastComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'La Bourrée de Paris';

  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

  async ngOnInit() {
    registerLocaleData(localeFr);
    if (isPlatformBrowser(this.platformId)) {
      const AOS = (await import('aos')).default;
      AOS.init({
        duration: 1200,
      });
      try {
        initFlowbite();
        initDropdowns();
        initCollapses();
      } catch(err) {    
        console.error("Error : ", err);
      }
    }
  }
}