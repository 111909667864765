<div class="container mx-auto p-6 flex flex-col gap-4">
    <!-- Resource Header -->
    <div class="" *ngIf="ressource">
        <h1 class="text-4xl font-extrabold font-alice">{{ressource.nom}}</h1>
        <div class="text-gray-600">
            <p class="text-lg"><strong>Type:</strong> {{ressource.type}}</p>
            <p class="text-lg" *ngIf="ressource.nom_musique"><strong>Nom de la musique:</strong> {{ressource.nom_musique}}</p>
        </div>
        <div class="prose max-w-none">
            <p>{{ressource.description}}</p>
        </div>
    </div>

    <!-- Audio Section -->
    <div class="" *ngIf="audioFiles.length > 0">
        <h2 class="text-2xl font-bold mb-4">Audio</h2>
        <div class="border rounded-lg">
            <div *ngFor="let file of audioFiles" class="border-b p-4 flex flex-row gap-2 items-center">
                <p class="text-md font-raleway font-semibold">{{file.nom}}</p>
                <audio controls class="w-full">
                    <source [src]="file.url" type="audio/mpeg">
                    Votre navigateur ne supporte pas la lecture audio.
                </audio>
            </div>
        </div>
    </div>

    <!-- PDF Section -->
    <div class="" *ngIf="pdfFiles.length > 0">
        <h2 class="text-2xl font-bold mb-4">Documents</h2>
        <div class="space-y-4">
            <div *ngFor="let file of pdfFiles" class="border rounded-lg p-4">
                <h3 class="text-lg font-semibold mb-2">{{file.nom}}</h3>
                <ngx-doc-viewer 
                    [url]="file.url" 
                    viewer="google"
                    style="width:100%;height:600px;"
                ></ngx-doc-viewer>
            </div>
        </div>
    </div>

    <!-- YouTube Section -->
    <div class="" *ngIf="youtubeVideos.length > 0">
        <h2 class="text-2xl font-bold mb-4">Vidéos</h2>
        <div class="space-y-4">
            <div *ngFor="let video of youtubeVideos" class="border rounded-lg p-4">
                <h3 class="text-lg font-semibold mb-2">{{video.nom}}</h3>
                <div class="relative" style="padding-bottom: 56.25%;">
                    <iframe 
                        [src]="video.safeUrl" 
                        class="absolute top-0 left-0 w-full h-full"
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen>
                    </iframe>
                </div>
            </div>
        </div>
    </div>
</div>
