import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EvenementService } from '../../_services/evenement/evenement.service';
import { Evenement } from '../../_interfaces/evenement';
import { Inscription } from '../../_interfaces/inscription';
import { InscriptionService } from '../../_services/inscription/inscription.service';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../_services/auth/auth.service';
import { Observable } from 'rxjs';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-evenements-details',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './evenements-details.component.html',
  styleUrl: './evenements-details.component.scss'
})
export class EvenementsDetailsComponent {
  route = inject(ActivatedRoute)
  evenementService = inject(EvenementService)
  inscriptionService = inject(InscriptionService)
  authService = inject(AuthService)

  private presents?: string[] = undefined;
  private pas_repondu?: string[] = undefined;

  public nbPresent = 0;
  public nbNonPresent = 0;
  
  
  evenement: Evenement =  {
    key: "",
    nom: "",
    image: "",
    description: "",
    dateDebut: new Date().toISOString(),
    dateFin: new Date().toISOString(),
    lieu: "",
    private: false
  }
  inscription !: Inscription | undefined;
  inscriptions : Inscription[] = [];
  
  isCommentModalOpen = false;
  tempComment: string = '';
  
  constructor() { }
  
  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = params['id'];
      this.evenementService.readOneEvenement(id).then(evenement => {
        this.evenement = evenement
        this.inscriptionService.readOneInscriptionOfMeAndEvenement(id)
        .then(inscription => {
          this.inscription = inscription
        })
        .catch(error => {
          console.error('Erreur lors de la récupération de l\'inscription:', error);
        });
        this.inscriptionService.readAllInscriptionOfEvenement(id).then(inscriptions => {
          this.inscriptions = inscriptions;
          const trueInscriptions = inscriptions.filter(inscription => inscription.is_present === true).sort((a, b) => {
            if(a.nom_prenom < b.nom_prenom) { return -1; }
            if(a.nom_prenom > b.nom_prenom) { return 1; }
            return 0;
          });
          this.nbPresent = trueInscriptions.length;
          const falseInscriptions = inscriptions.filter(inscription => inscription.is_present === false).sort((a, b) => {
            if(a.nom_prenom < b.nom_prenom) { return -1; }
            if(a.nom_prenom > b.nom_prenom) { return 1; }
            return 0;
          });
          this.nbNonPresent = falseInscriptions.length;
          this.inscriptions = [...trueInscriptions, ...falseInscriptions];
        });
        if(this.authService.isAdmin$){
          this.inscriptionService.getEmailsOfUsersPresentAtEvent(id).then(emails => {
            this.presents = emails;
          });
          this.inscriptionService.getEmailsOfUsersNotInscribedToEvent(id).then(emails => {
            this.pas_repondu = emails;
          });
        }
      });
    });
  }
  
  setInscription(evenement_key: string, is_present: boolean){
    this.inscriptionService.createInscription("", evenement_key, is_present).then((inscription) => {
      this.inscription = inscription;
    });
  }
  
  updateComment(comment: string) {
    if (this.inscription && this.evenement.key) {
      const updatedInscription: Inscription = {
        ...this.inscription,
        commentaire: comment
      };
      this.inscriptionService.updateInscription(updatedInscription).then(() => {
        this.inscription = updatedInscription;
      });
    }
  }
  
  get isAuth$(): Observable<boolean> {
    return this.authService.isAuth$;
  }
  
  get isAdmin$(): Observable<boolean> {
    return this.authService.isAdmin$;
  }

  get mailto_present(): string | undefined{
    if(this.presents === undefined){
      return undefined;
    }
    return "mailto:?bcc=" + this.presents.join(",");
  }

  get mailto_pas_repondu(): string | undefined{
    if(this.pas_repondu === undefined){
      return undefined;
    }
    return "mailto:?bcc=" + this.pas_repondu.join(",");
  }

  openCommentModal() {
    if (this.inscription) {
      this.tempComment = this.inscription.commentaire;
      this.isCommentModalOpen = true;
    }
  }

  closeCommentModal() {
    this.isCommentModalOpen = false;
    this.tempComment = '';
  }

  confirmComment() {
    if (this.inscription && this.evenement.key) {
      const updatedInscription: Inscription = {
        ...this.inscription,
        commentaire: this.tempComment
      };
      this.inscriptionService.updateInscription(updatedInscription).then(() => {
        this.inscription = updatedInscription;
        this.closeCommentModal();
      });
    }
  }
}
