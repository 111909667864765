import { Injectable, inject } from '@angular/core';
import { Database, DatabaseReference, ref, list, push, object, get, update, remove, ThenableReference } from '@angular/fire/database';
import { Evenement } from '../../_interfaces/evenement';
import { Observable, combineLatest, map, of, switchMap } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class EvenementService {
  evenementsPrivesRef: DatabaseReference;
  evenementsPublicsRef: DatabaseReference;
  
  private authService: AuthService = inject(AuthService);
  private database: Database = inject(Database);
  
  constructor(){
    this.evenementsPrivesRef = ref(this.database, '/evenements_prives')
    this.evenementsPublicsRef = ref(this.database, '/evenements_publics')
  }
  
  readEvenementsPrives(): Observable<Evenement[]>{
    return list(this.evenementsPrivesRef).pipe(map(queryChanges => queryChanges.map(change => ({ key: change.snapshot.key, ...change.snapshot.val() })))) as Observable<Evenement[]>;
  }
  
  readEvenementsPublics(): Observable<Evenement[]>{
    return list(this.evenementsPublicsRef).pipe(map(queryChanges => queryChanges.map(change => ({ key: change.snapshot.key, ...change.snapshot.val() })))) as Observable<Evenement[]>;
  }
  
  readAllEvenements(): Observable<Evenement[]>{
    if(this.authService.isAuth$){
      return combineLatest([this.readEvenementsPrives(), this.readEvenementsPublics()]).pipe(map(([evenementsPrives, evenementsPublics]) => {
        return [...evenementsPrives, ...evenementsPublics].sort((a, b) => new Date(a.dateDebut).getTime() - new Date(b.dateDebut).getTime());
      }));
    }else{
      return this.readEvenementsPublics().pipe(map(evenements => evenements.sort((a, b) => new Date(a.dateDebut).getTime() - new Date(b.dateDebut).getTime())));
    }
  }

  readAllFutureEvenements(): Observable<Evenement[]>{
    let today = new Date()
    today.setHours(0, 0, 0, 0);
    return this.readAllEvenements().pipe(map(evenements => evenements.filter(evenement => new Date(evenement.dateDebut) >= today)));
  }

  readAllFuturePublicEvenements(): Observable<Evenement[]>{
    let today = new Date()
    today.setHours(0, 0, 0, 0);
    return this.readEvenementsPublics().pipe(map(evenements => evenements.filter(evenement => new Date(evenement.dateDebut) >= today)));
  }
  
  readOneEvenement(key: string): Promise<Evenement> {
    // Première tentative : rechercher dans les événements publics
    return get(ref(this.database, `/evenements_publics/${key}`))
    .then(snapshot => {
      if (snapshot.exists()) {
        const evenementPublic = snapshot.val() as Evenement;
        evenementPublic.key = key;
        return evenementPublic;
      } else {
        // Si l'événement n'est pas trouvé dans les publics, rechercher dans les privés
        return get(ref(this.database, `/evenements_prives/${key}`)).then(snapshotPriv => {
          if (snapshotPriv.exists()) {
            const evenementPrive = snapshotPriv.val() as Evenement;
            evenementPrive.key = key;
            return evenementPrive;
          } else {
            // Si l'événement n'est trouvé ni dans les publics, ni dans les privés
            throw new Error(`Aucun évènement trouvé avec la clé: ${key}`);
          }
        });
      }
    });
  }

  readXEvenements(x: number): Observable<Evenement[]>{
    return this.authService.isAuth$.pipe(
      switchMap((isAuth): Observable<[Evenement[], Evenement[]]> => {
        if(isAuth){
          return combineLatest([this.readEvenementsPrives(), this.readEvenementsPublics()]);
        }else{
          return combineLatest([of([]), this.readEvenementsPublics()]);
        }
      }),
      map(([evenementsPrives, evenementsPublics]) => {
        const evenements = [...evenementsPrives, ...evenementsPublics];
        return this.filterAndSortEvenements(evenements, x);
      })
    );
  }

  private filterAndSortEvenements(evenements: Evenement[], x: number): Evenement[] {
    // Filtrer les événements qui sont dans le futur ou aujourd'hui a partir de minuit
    const evenementsFuturs = evenements.filter(evenement => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const eventDate = new Date(evenement.dateDebut);
      return eventDate >= today;
    });
    // Trier les événements futurs par date de début
    evenementsFuturs.sort((a, b) => 
      new Date(a.dateDebut).getTime() - new Date(b.dateDebut).getTime()
    );
    // Retourner les x premiers événements
    return evenementsFuturs.slice(0, x);
  }
  
  createEvenement(evenement: Evenement, isPrivate: boolean): ThenableReference{
    if(isPrivate){
      return push(this.evenementsPrivesRef,{
        nom: evenement.nom,
        description: evenement.description,
        dateDebut: evenement.dateDebut,
        dateFin: evenement.dateFin,
        lieu: evenement.lieu,
        image: evenement.image,
        private: true
      });
    }else{
      return push(this.evenementsPublicsRef,{
        nom: evenement.nom,
        description: evenement.description,
        dateDebut: evenement.dateDebut,
        dateFin: evenement.dateFin,
        lieu: evenement.lieu,
        image: evenement.image,
        private: false
      });
    }
  }

  updateEvenement(key: string, evenement: Evenement, old_private: boolean): Promise<void>{
    if(old_private != evenement.private){
      if(old_private){
        // Si le status de l'événement change de privé à public, on le supprime des événements privés et on l'ajoute aux événements publics
        return Promise.all([
          remove(ref(this.database, `/evenements_prives/${key}`)),
          push(this.evenementsPublicsRef,{
            nom: evenement.nom,
            description: evenement.description,
            dateDebut: evenement.dateDebut,
            dateFin: evenement.dateFin,
            lieu: evenement.lieu,
            image: evenement.image,
            private: false
          })
        ]).then(() => {});
      }else{
        // Si le status de l'événement change de public à privé, on le supprime des événements publics et on l'ajoute aux événements privés
        return Promise.all([
          remove(ref(this.database, `/evenements_publics/${key}`)),
          push(this.evenementsPrivesRef,{
            nom: evenement.nom,
            description: evenement.description,
            dateDebut: evenement.dateDebut,
            dateFin: evenement.dateFin,
            lieu: evenement.lieu,
            image: evenement.image,
            private: true
          })
        ]).then(() => {});
      }
    }else{
      // Si le status de l'événement reste le même, on met juste à jour les informations
      let path = "evenements_publics"
      if(evenement.private){
        path = "evenements_prives"
      }
      return update(ref(this.database, `${path}/${key}`),{
        nom: evenement.nom,
        description: evenement.description,
        dateDebut: evenement.dateDebut,
        dateFin: evenement.dateFin,
        lieu: evenement.lieu,
        image: evenement.image,
        private: evenement.private
      });
    }
  }

  deleteEvenement(evenement: Evenement): Promise<void>{
    if(evenement.private){
      return remove(ref(this.database, `/evenements_prives/${evenement.key}`));
    }else{
      return remove(ref(this.database, `/evenements_publics/${evenement.key}`));
    }
  }
}
