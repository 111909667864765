import { Component } from '@angular/core';
import { UtilisateurService } from '../../../_services/utilisateur/utilisateur.service';
import { EvenementService } from '../../../_services/evenement/evenement.service';
import { RessourceService } from '../../../_services/ressource/ressource.service';
import { ActualiteService } from '../../../_services/actualite/actualite.service';
import { Evenement } from '../../../_interfaces/evenement';
import { InscriptionService } from '../../../_services/inscription/inscription.service';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-admin-home',
  standalone: true,
  imports: [NgClass],
  templateUrl: './admin-home.component.html',
  styleUrl: './admin-home.component.scss'
})
export class AdminHomeComponent {
  nbMembresInscrits: number = 0;
  nbSortieAnnee: number = 0;
  nbActualites: number = 0;
  nbRessources: number = 0;
  prochaineSortie: Evenement | null = null;
  nbInscritsProchaineSortie: number = 0;

  constructor (
    private utilisateurService: UtilisateurService,
    private evenementService: EvenementService,
    private actualiteService: ActualiteService,
    private ressourceService: RessourceService,
    private inscriptionService: InscriptionService
  ) {
    this.utilisateurService.readAllUtilisateurs().subscribe((data) => {
      this.nbMembresInscrits = data.length;
    });

    this.evenementService.readAllEvenements().subscribe((data) => {
      const currentYear = new Date().getFullYear();
      this.nbSortieAnnee = data.filter(evenement => {
        const eventDate = new Date(evenement.dateDebut);
        return eventDate.getFullYear() === currentYear;
      }).length;
    });

    this.actualiteService.readAllActualites().subscribe((data) => {
      this.nbActualites = data.length;
    });

    // this.ressourceService.readAllRessources().subscribe((data) => {
    //   this.nbRessources = data.length;
    // });

    this.evenementService.readXEvenements(1).subscribe((data) => {
      this.prochaineSortie = data[0];
      if(this.prochaineSortie && this.prochaineSortie.key){
        this.inscriptionService.readAllInscriptionOfEvenement(this.prochaineSortie.key).then((data) => {
          this.nbInscritsProchaineSortie = data.filter(inscription => inscription.is_present === true).length;
        });
      }
    });

    

  }

}
