import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActualiteService } from '../../_services/actualite/actualite.service';
import { Actualite } from '../../_interfaces/actualite';
import { ActualiteCardComponent } from '../../_components/actualite-card/actualite-card.component'

@Component({
  selector: 'app-actualites',
  standalone: true,
  imports: [CommonModule, ActualiteCardComponent],
  templateUrl: './actualites.component.html'
})
export class ActualitesComponent {
  actualiteService = inject(ActualiteService);
  actualites: Actualite[] = [];

  constructor() {
    this.readActualites();
  }

  readActualites() {
    this.actualiteService.readPublishedActualites().subscribe(
      actualites => this.actualites = actualites,
      error => console.error('Erreur lors de la récupération des actualités:', error)
    );
  }
}
