<div class="container mx-auto p-6 font-raleway">
    <h1 class="text-4xl lg:text-6xl font-extrabold font-alice mb-8">Ressources</h1>

    <mat-form-field class="w-full">
        <mat-label>Filtrer</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Bourrée" #input>
    </mat-form-field>

    <!-- Grid de cartes responsive -->
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div *ngFor="let ressource of filteredRessources" 
             class="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200 p-4 cursor-pointer"
             (click)="viewDetails(ressource.key)">
            <div class="flex items-center gap-4">
                <!-- Icône basée sur le type -->
                <div class="flex-shrink-0">
                    <mat-icon class="text-2xl text-gray-600" [ngSwitch]="ressource.type">
                        <ng-container *ngSwitchCase="'DANSE'">directions_run</ng-container>
                        <ng-container *ngSwitchCase="'MUSIQUE'">music_note</ng-container>
                        <ng-container *ngSwitchCase="'CHANT'">mic</ng-container>
                        <ng-container *ngSwitchCase="'SPECTACLE'">theater_comedy</ng-container>
                        <ng-container *ngSwitchDefault>description</ng-container>
                    </mat-icon>
                </div>
                <div class="flex-grow">
                    <h3 class="text-lg font-semibold text-gray-800 hover:text-blue-600">{{ressource.nom}}</h3>
                    <p class="text-sm text-gray-600">{{ressource.type}}</p>
                </div>
            </div>
        </div>
    </div>

    <!-- Message quand aucune donnée -->
    <div *ngIf="filteredRessources.length === 0" class="text-center py-8">
        <div *ngIf="input.value; else noData">
            Aucune ressource ne correspond au filtre "{{input.value}}"
        </div>
        <ng-template #noData>
            Aucune ressource disponible
        </ng-template>
    </div>
</div>