<div class="flex flex-col gap-4">
    <div class="flex flex-col lg:flex-row gap-4">
        <!-- nb Membres inscrits -->
        <div class="w-full lg:w-1/4 p-6 lg:p-10 flex flex-col justify-center items-center bg-gradient-to-br from-blue-200 via-blue-300 to-blue-400 rounded-lg text-neutral-800">
            <p class="font-semibold text-3xl lg:text-4xl mb-2 font-raleway">{{ nbMembresInscrits }}</p>
            <p class="text-xs lg:text-sm font-raleway font-semibold uppercase text-neutral-500 text-center">Membres inscrits</p>
        </div>
        <!-- nb Sortie de l'année -->
        <div class="w-full lg:w-1/4 p-6 lg:p-10 flex flex-col justify-center items-center bg-gradient-to-tr from-green-200 via-green-300 to-green-400 rounded-lg text-neutral-800">
            <p class="font-semibold text-3xl lg:text-4xl mb-2 font-raleway">{{ nbSortieAnnee }}</p>
            <p class="text-xs lg:text-sm font-raleway font-semibold uppercase text-neutral-500 text-center">Sorties cette année</p>
        </div>
        <!-- nb Actualités -->
        <div class="w-full lg:w-1/4 p-6 lg:p-10 flex flex-col justify-center items-center bg-gradient-to-bl from-purple-200 via-purple-300 to-purple-400 rounded-lg text-neutral-800">
            <p class="font-semibold text-3xl lg:text-4xl mb-2 font-raleway">{{ nbActualites }}</p>
            <p class="text-xs lg:text-sm font-raleway font-semibold uppercase text-neutral-500 text-center">Actualités</p>
        </div>
        <!-- nb Ressources -->
        <div class="w-full lg:w-1/4 p-6 lg:p-10 flex flex-col justify-center items-center bg-gradient-to-tl from-orange-200 via-orange-300 to-orange-400 rounded-lg text-neutral-800">
            <p class="font-semibold text-3xl lg:text-4xl mb-2 font-raleway">{{ nbRessources }}</p>
            <p class="text-xs lg:text-sm font-raleway font-semibold uppercase text-neutral-500 text-center">Ressources</p>
        </div>
    </div>
    
    <div class="flex flex-col lg:flex-row gap-4">
        <div class="w-full lg:w-1/2 p-6 lg:p-10 flex flex-col justify-center items-center rounded-lg relative h-48 lg:h-64 overflow-hidden">
            <div class="absolute inset-0">
                <img [src]="prochaineSortie?.image" alt="Image de la sortie" class="w-full h-full object-cover">
                <div class="absolute inset-0 bg-black bg-opacity-50"></div>
            </div>
            <div class="relative z-10 text-white text-center">
                <p class="text-xs lg:text-sm">Prochaine sortie :</p>
                <p class="font-bold text-2xl lg:text-4xl mb-2">{{ prochaineSortie?.nom }}</p>
                <p class="text-xs lg:text-sm">{{ prochaineSortie?.lieu }}</p>
            </div>
        </div>
        <div class="w-full lg:w-1/4 p-6 lg:p-10 flex flex-col justify-center items-center bg-gradient-to-br from-red-800 via-red-900 to-red-950 rounded-lg text-white">
            <p class="font-semibold text-3xl lg:text-4xl mb-2 font-raleway">{{ nbInscritsProchaineSortie }}</p>
            <p class="text-xs lg:text-sm font-raleway font-semibold uppercase text-neutral-200 text-center">Présent à la sortie</p>
        </div>
    </div>

    <!-- <div class="flex flex-col lg:flex-row gap-4">
        <div class="w-full lg:w-1/3 p-4 flex flex-col justify-center items-center bg-gray-200 rounded-lg">
            <p class="font-bold text-3xl lg:text-4xl mb-2">+</p>
            <p class="text-xs lg:text-sm text-gray-600 text-center">Ajouter une actualité</p>
        </div>
        <div class="w-full lg:w-1/3 p-4 flex flex-col justify-center items-center bg-gray-200 rounded-lg">
            <p class="font-bold text-3xl lg:text-4xl mb-2">+</p>
            <p class="text-xs lg:text-sm text-gray-600 text-center">Ajouter une ressource</p>
        </div>
        <div class="w-full lg:w-1/3 p-4 flex flex-col justify-center items-center bg-gray-200 rounded-lg">
            <p class="font-bold text-3xl lg:text-4xl mb-2">+</p>
            <p class="text-xs lg:text-sm text-gray-600 text-center">Ajouter un événement</p>
        </div>
    </div> -->
</div>