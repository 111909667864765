import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Ressource } from '../../../_interfaces/ressource';
import { Fichier } from '../../../_interfaces/fichier';
import { RessourceType, RessourceFileType } from '../../../_enums/ressource_type.enum';
import { RessourceService } from '../../../_services/ressource/ressource.service';
import { MatSnackBarModule, MatSnackBar } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FirebaseStorageService } from '../../../_services/firebase-storage/firebase-storage.service';

@Component({
  selector: 'app-ressource-edition',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSnackBarModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule
  ],
  templateUrl: './ressource-edition.component.html',
  styleUrl: './ressource-edition.component.scss'
})
export class RessourceEditionComponent implements OnInit {
  resourceForm: FormGroup;
  ressources: Ressource[] = [];
  ressource: Ressource | null = null;
  files: Fichier[] = [];
  isLoading = false;
  isModalOpen = false;
  isDeleteModalOpen = false;
  resourceToDelete: Ressource | null = null;
  uploadProgress = 0;
  ressourceTypes = Object.values(RessourceType);
  previewUrls: string[] = [];
  RessourceType = RessourceType;
  acceptedFileTypes = '.jpg,.jpeg,.png,.gif,.webp,.mp3,.wav,.ogg,.pdf';
  private dropdownStates: { [key: string]: boolean } = {};

  constructor(
    private fb: FormBuilder,
    private firebaseStorageService: FirebaseStorageService,
    private ressourceService: RessourceService,
    private snackBar: MatSnackBar,
    private elementRef: ElementRef
  ) {
    this.resourceForm = this.fb.group({
      nom: ['', [Validators.required, Validators.minLength(3)]],
      nom_musique: [''],
      description: ['', Validators.required],
      type: ['', Validators.required],
      youtubeUrl: [''],
      youtubeName: ['']
    });
  }

  ngOnInit() {
    this.loadRessources();
  }

  loadRessources() {
    this.ressourceService.getAllRessources().subscribe((ressources: Ressource[]) => {
      this.ressources = ressources;
    });
  }

  openModal() {
    this.isModalOpen = true;
    this.ressource = null;
    this.resetForm();
  }

  closeModal() {
    this.isModalOpen = false;
    this.resetForm();
  }

  openDeleteModal(ressource: Ressource) {
    this.resourceToDelete = ressource;
    this.isDeleteModalOpen = true;
  }

  closeDeleteModal() {
    this.isDeleteModalOpen = false;
    this.resourceToDelete = null;
  }

  onEdit(ressource: Ressource) {
    this.ressource = ressource;
    this.isModalOpen = true;
    this.files = ressource.fichiers || [];
    this.resourceForm.patchValue({
      nom: ressource.nom,
      nom_musique: ressource.nom_musique,
      description: ressource.description,
      type: ressource.type
    });
  }

  onDelete(ressource: Ressource) {
    this.openDeleteModal(ressource);
  }

  confirmDelete() {
    if (this.resourceToDelete) {
      this.isLoading = true;
      // Delete associated files first
      const deletePromises = (this.resourceToDelete.fichiers || [])
        .filter(file => file.type !== RessourceFileType.YOUTUBE)
        .map(file => this.firebaseStorageService.deleteFile(file.url));

      Promise.all(deletePromises)
        .then(() => this.ressourceService.deleteRessource(this.resourceToDelete!.key!))
        .then(() => {
          this.snackBar.open('Ressource supprimée avec succès', 'Fermer', { duration: 3000 });
          this.loadRessources();
        })
        .catch(error => {
          console.error('Erreur lors de la suppression:', error);
          this.snackBar.open('Erreur lors de la suppression de la ressource', 'Fermer', { duration: 3000 });
        })
        .finally(() => {
          this.isLoading = false;
          this.closeDeleteModal();
        });
    }
  }

  onSubmit() {
    if (this.resourceForm.valid) {
      this.isLoading = true;
      const ressourceData: Ressource = {
        ...this.resourceForm.value,
        fichiers: this.files,
        date_modification: new Date().toISOString()
      };

      if (!this.ressource) {
        // Create new resource
        ressourceData.date_creation = new Date().toISOString();
        this.ressourceService.createRessource(ressourceData)
          .then(() => {
            this.snackBar.open('Ressource créée avec succès', 'Fermer', { duration: 3000 });
            this.loadRessources();
            this.closeModal();
          })
          .catch(error => {
            console.error('Erreur lors de la création:', error);
            this.snackBar.open('Erreur lors de la création de la ressource', 'Fermer', { duration: 3000 });
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        // Update existing resource
        this.ressourceService.updateRessource(this.ressource.key!, ressourceData)
          .then(() => {
            this.snackBar.open('Ressource mise à jour avec succès', 'Fermer', { duration: 3000 });
            this.loadRessources();
            this.closeModal();
          })
          .catch(error => {
            console.error('Erreur lors de la mise à jour:', error);
            this.snackBar.open('Erreur lors de la mise à jour de la ressource', 'Fermer', { duration: 3000 });
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    }
  }

  onFileChange(event: any) {
    const files = event.target.files;
    if (!files) return;

    Array.from<File>(files).forEach((file: File) => {
      const fileType = this.determineFileType(file);
      if (fileType === RessourceFileType.UNKNOWN || fileType === RessourceFileType.VIDEO) {
        this.snackBar.open('Type de fichier non autorisé. Seuls les images, fichiers audio et PDF sont acceptés.', 'Fermer', {
          duration: 3000
        });
        return;
      }
      
      this.isLoading = true;
      this.firebaseStorageService.uploadFIle(file, `ressources/${Date.now()}_${file.name}`)
        .then((url) => {
          this.files.push({
            nom: file.name,
            type: fileType,
            url: url,
          });
        })
        .catch(error => {
          console.error('Erreur lors du téléchargement:', error);
          this.snackBar.open('Erreur lors du téléchargement du fichier', 'Fermer', { duration: 3000 });
        })
        .finally(() => {
          this.isLoading = false;
        });
    });
  }

  addYoutubeLink() {
    const youtubeUrl = this.resourceForm.get('youtubeUrl')?.value;
    const youtubeName = this.resourceForm.get('youtubeName')?.value;

    if (!youtubeUrl || !youtubeName) {
      this.snackBar.open('Veuillez remplir l\'URL YouTube et le nom', 'Fermer', {
        duration: 3000
      });
      return;
    }

    if (!this.isValidYoutubeUrl(youtubeUrl)) {
      this.snackBar.open('URL YouTube invalide', 'Fermer', {
        duration: 3000
      });
      return;
    }

    this.files.push({
      nom: youtubeName,
      type: RessourceFileType.YOUTUBE,
      url: youtubeUrl,
    });

    this.resourceForm.patchValue({
      youtubeUrl: '',
      youtubeName: ''
    });
  }

  private isValidYoutubeUrl(url: string): boolean {
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+/;
    return youtubeRegex.test(url);
  }

  private determineFileType(file: File): RessourceFileType {
    const extension = file.name.split('.').pop()?.toLowerCase();
    const mimeType = file.type.toLowerCase();

    if (mimeType.startsWith('image/') || ['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(extension || '')) {
      return RessourceFileType.IMAGE;
    }
    if (mimeType.startsWith('audio/') || ['mp3', 'wav', 'ogg'].includes(extension || '')) {
      return RessourceFileType.AUDIO;
    }
    if (mimeType === 'application/pdf' || extension === 'pdf') {
      return RessourceFileType.PDF;
    }
    
    return RessourceFileType.UNKNOWN;
  }

  resetForm() {
    this.resourceForm.reset();
    this.files = [];
  }

  removeFile(index: number) {
    const file = this.files[index];
    if (file.type !== RessourceFileType.YOUTUBE) {
      this.firebaseStorageService.deleteFile(file.url)
        .then(() => {
          this.files.splice(index, 1);
        })
        .catch(error => {
          console.error('Erreur lors de la suppression du fichier:', error);
          this.snackBar.open('Erreur lors de la suppression du fichier', 'Fermer', { duration: 3000 });
        });
    } else {
      this.files.splice(index, 1);
    }
  }

  getFileIcon(fileType: RessourceFileType): string {
    switch (fileType) {
      case RessourceFileType.IMAGE:
        return 'image';
      case RessourceFileType.AUDIO:
        return 'music_note';
      case RessourceFileType.PDF:
        return 'picture_as_pdf';
      case RessourceFileType.YOUTUBE:
        return 'smart_display';
      default:
        return 'insert_drive_file';
    }
  }

  toggleDropdown(key: string, isMobile: boolean) {
    const dropdownId = `dropdown-${isMobile ? '' : 'desktop-'}${key}`;
    const buttonId = `dropdownButton-${isMobile ? '' : 'desktop-'}${key}`;
    const dropdown = document.getElementById(dropdownId);
    const button = document.getElementById(buttonId);

    // Close all other dropdowns
    document.querySelectorAll('[id^="dropdown-"]').forEach(el => {
      if (el.id !== dropdownId) {
        el.classList.add('hidden');
      }
    });

    if (dropdown && button) {
      const buttonRect = button.getBoundingClientRect();
      const isHidden = dropdown.classList.contains('hidden');

      // Toggle the dropdown
      dropdown.classList.toggle('hidden');

      if (!isHidden) return; // If we're hiding the dropdown, no need to calculate position

      // Position the dropdown relative to the button
      if (window.innerWidth - buttonRect.right < dropdown.offsetWidth) {
        // If there's not enough space on the right, align to the right edge of the button
        dropdown.style.right = '0';
        dropdown.style.left = 'auto';
      } else {
        // Otherwise, align to the left edge of the button
        dropdown.style.left = '0';
        dropdown.style.right = 'auto';
      }
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      // Click was outside the component, close all dropdowns
      Object.keys(this.dropdownStates).forEach(key => {
        this.dropdownStates[key] = false;
        const element = document.getElementById(key);
        if (element) {
          element.classList.add('hidden');
        }
      });
    }
  }
}
