import { Routes } from '@angular/router';
import { HomeComponent } from './_pages/home/home.component';
import { LoginComponent } from './_pages/login/login.component';
import { PageNotFoundComponent } from './_pages/page-not-found/page-not-found.component';
import { AdminGuard } from './_guards/admin/admin.guard';
import { AuthGuard } from './_guards/auth/auth.guard';
import { AgendaComponent } from './_pages/agenda/agenda.component';
import { RegisterComponent } from './_pages/register/register.component';
import { EvenementEditionComponent } from './_pages/_admin/evenement-edition/evenement-edition.component';
import { EvenementsDetailsComponent } from './_pages/evenements-details/evenements-details.component';
import { RessourcesComponent } from './_pages/ressources/ressources.component';
import { RessourcesDetailsComponent } from './_pages/ressources-details/ressources-details.component';
import { UtilisateurEditionComponent } from './_pages/_admin/utilisateur-edition/utilisateur-edition.component';
import { NewsletterComponent } from './_pages/_admin/newsletter/newsletter.component';
import { ActualitesComponent } from './_pages/actualites/actualites.component';
import { ActualiteEditionComponent } from './_pages/_admin/actualite-edition/actualite-edition.component';
import { ActualiteDetailsComponent } from './_pages/actualite-details/actualite-details.component';
import { AProposComponent } from './_pages/a-propos/a-propos.component';
import { EvenementsComponent } from './_pages/evenements/evenements.component';
import { AdminHomeComponent } from './_pages/_admin/admin-home/admin-home.component';
import { RessourceEditionComponent } from './_pages/_admin/ressource-edition/ressource-edition.component';
import { MainLayoutComponent } from './_layouts/main-layout/main-layout.component';
import { AuthLayoutComponent } from './_layouts/auth-layout/auth-layout.component';
import { AdminLayoutComponent } from './_layouts/admin-layout/admin-layout.component';

export const routes: Routes = [
    {
        path: '',
        component: MainLayoutComponent,
        children: [
            {path: "", component: HomeComponent},
            {path: "a-propos", component: AProposComponent},
            {path: "evenements", component: EvenementsComponent},
            {path: "actualites", component: ActualitesComponent},
            {path: "actualites/:id", component: ActualiteDetailsComponent},
            {
                path: "agenda",
                canActivate: [AuthGuard],
                children: [
                    {path: "", component: AgendaComponent},
                    {path: ":id", component: EvenementsDetailsComponent}
                ]
            },
            {
                path: "ressources",
                canActivate: [AuthGuard],
                children: [
                    {path: "", component: RessourcesComponent},
                    {path: ":id", component: RessourcesDetailsComponent}
                ]
            },
        ]
    },
    {
        path: "",
        component: AuthLayoutComponent,
        children: [
            {path: "login", component: LoginComponent},
            {path: "register", component: RegisterComponent},
        ]
    },
    {
        path: "admin",
        component: AdminLayoutComponent,
        canActivate: [AdminGuard],
        children: [
            {path: "", component: AdminHomeComponent},
            {path: "evenements", component: EvenementEditionComponent},
            {path: "utilisateurs", component: UtilisateurEditionComponent},
            {path: "newsletter", component: NewsletterComponent},
            {path: "actualites", component: ActualiteEditionComponent},
            {path: "ressources", component: RessourceEditionComponent}
        ]
    },
    {path: "**", component: PageNotFoundComponent},
];
