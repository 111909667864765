.prose {
  max-width: 90%;
  margin: 0 auto;
  font-size: 1.125rem;
  line-height: 1.75;
  padding: 0 1rem;
  
  @media (min-width: 1200px) {
    max-width: 80%;
  }
  
  @media (min-width: 1600px) {
    max-width: 70%;
  }
  
  @media (max-width: 768px) {
    max-width: 100%;
    font-size: 1rem;
    line-height: 1.6;
    padding: 0 0.5rem;
  }
  
  p {
    margin-bottom: 1.25em;
    word-wrap: break-word;
    overflow-wrap: break-wrap;
    hyphens: auto;
    white-space: normal !important;
  }

  img {
    display: block;
    margin: 2rem auto;
    max-width: 100%;
    height: auto;
    border-radius: 0.5rem;
    
    @media (max-width: 768px) {
      margin: 1rem auto;
      border-radius: 0.25rem;
    }
  }
}

.quill-content {
  /* Text alignment */
  .ql-align-center {
    text-align: center;
  }

  .ql-align-right {
    text-align: right;
  }

  .ql-align-left {
    text-align: left;
  }

  .ql-align-justify {
    text-align: justify;
  }

  /* Headers */
  h1 {
    font-size: 2em;
    margin: 1em 0 0.5em;
    font-family: 'Alice', serif;
    color: #22232B;
    
    @media (max-width: 768px) {
      font-size: 1.75em;
      margin: 0.75em 0 0.4em;
    }
  }

  h2 {
    font-size: 1.5em;
    margin: 1em 0 0.5em;
    font-family: 'Alice', serif;
    color: #22232B;
    
    @media (max-width: 768px) {
      font-size: 1.25em;
      margin: 0.75em 0 0.4em;
    }
  }

  /* Text formatting */
  strong, b {
    font-weight: 600;
    color: #22232B;
    margin: 0 0.1em;
  }

  /* Links */
  a {
    color: #3B4193;
    text-decoration: underline;
    font-weight: 500;
    margin: 0 0.1em;
    
    &:hover {
      color: #5F6ACE;
    }

    strong, b {
      color: inherit;
    }
  }

  /* Spans */
  span {
    margin: 0 0.1em;
    
    &:first-child {
      margin-left: 0;
    }
    
    &:last-child {
      margin-right: 0;
    }
  }

  p {
    word-spacing: 0.1em;
  }

  /* Lists */
  ul, ol {
    margin: 1em 0;
    padding-left: 2em;
    
    @media (max-width: 768px) {
      padding-left: 1.5em;
      margin: 0.75em 0;
    }
  }

  /* Blockquotes */
  blockquote {
    border-left: 4px solid #3B4193;
    margin: 1.5em 0;
    padding-left: 1em;
    font-style: italic;
    color: #4a5568;
    
    @media (max-width: 768px) {
      margin: 1em 0;
      padding-left: 0.75em;
    }
  }

  /* Code blocks */
  pre {
    background-color: #f7fafc;
    border-radius: 0.375rem;
    padding: 1em;
    margin: 1em 0;
    overflow-x: auto;
    font-size: 0.875em;
    
    @media (max-width: 768px) {
      padding: 0.75em;
      font-size: 0.8125em;
    }
  }

  code {
    background-color: #f7fafc;
    padding: 0.2em 0.4em;
    border-radius: 0.25rem;
    font-size: 0.875em;
    
    @media (max-width: 768px) {
      font-size: 0.8125em;
    }
  }

  /* Images */
  img {
    max-width: 100%;
    height: auto;
    margin: 2rem auto;
    display: block;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    
    @media (max-width: 768px) {
      margin: 1.5rem auto;
      border-radius: 0.25rem;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1);
    }
  }

  /* Tables */
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 1em 0;
    overflow-x: auto;
    display: block;
    
    @media (max-width: 768px) {
      font-size: 0.875em;
    }
    
    th, td {
      border: 1px solid #e2e8f0;
      padding: 0.75em;
      text-align: left;
      
      @media (max-width: 768px) {
        padding: 0.5em;
      }
    }
    
    th {
      background-color: #f7fafc;
      font-weight: 600;
    }
    
    tr:nth-child(even) {
      background-color: #f8fafc;
    }
  }

  /* Subscript and Superscript */
  sub {
    vertical-align: sub;
    font-size: smaller;
  }

  sup {
    vertical-align: super;
    font-size: smaller;
  }
}

/* Empêcher les sauts de ligne après les tirets */
.prose p span.hyphen {
    white-space: nowrap;
}

/* Styles Quill */
.prose {
  .ql-align-center {
    text-align: center;
  }

  .ql-align-right {
    text-align: right;
  }

  .ql-align-left {
    text-align: left;
  }

  .ql-align-justify {
    text-align: justify;
  }

  blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
  }

  code {
    background-color: #f0f0f0;
    border-radius: 3px;
    padding: 2px 4px;
  }

  pre {
    background-color: #f0f0f0;
    border-radius: 3px;
    padding: 5px 10px;
    margin-bottom: 10px;
  }

  h1 {
    font-size: 2em;
    margin-bottom: 0.5em;
  }

  h2 {
    font-size: 1.5em;
    margin-bottom: 0.5em;
  }

  ul, ol {
    padding-left: 1.5em;
    margin-bottom: 10px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  a {
    color: #06c;
    text-decoration: underline;
  }

  sub {
    vertical-align: sub;
  }

  sup {
    vertical-align: super;
  }
}

