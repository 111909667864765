import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { RessourceService } from '../../_services/ressource/ressource.service';
import { Ressource } from '../../_interfaces/ressource';
import { MatIconModule } from '@angular/material/icon';
import { RessourceFileType } from '../../_enums/ressource_type.enum';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgxDocViewerModule } from 'ngx-doc-viewer';

@Component({
  selector: 'app-ressources-details',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    NgxDocViewerModule
  ],
  templateUrl: './ressources-details.component.html',
  styleUrl: './ressources-details.component.scss'
})
export class RessourcesDetailsComponent implements OnInit {
  ressource?: Ressource;
  RessourceFileType = RessourceFileType;
  audioFiles: any[] = [];
  pdfFiles: any[] = [];
  youtubeVideos: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ressourceService: RessourceService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.ressourceService.getRessourceById(id).then(ressource => {
        if (ressource) {
          this.ressource = ressource;   
          this.processFiles();
        } else {
          this.router.navigate(['/ressources']);
        }
      });
    }
  }

  private processFiles() {
    if (!this.ressource?.fichiers) return;

    this.ressource.fichiers.forEach(file => {
      switch (file.type) {
        case RessourceFileType.AUDIO:
          this.audioFiles.push(file);
          break;
        case RessourceFileType.PDF:
          this.pdfFiles.push(file);
          break;
        case RessourceFileType.YOUTUBE:
          this.youtubeVideos.push({
            ...file,
            safeUrl: this.sanitizer.bypassSecurityTrustResourceUrl(this.getYouTubeEmbedUrl(file.url))
          });
          break;
      }
    });
  }

  private getYouTubeEmbedUrl(url: string): string {
    const videoId = this.getYouTubeVideoId(url);
    return `https://www.youtube.com/embed/${videoId}`;
  }

  private getYouTubeVideoId(url: string): string {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : '';
  }
}
