import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Ressource } from '../../_interfaces/ressource';
import { Database, DatabaseReference, get, list, push, ref, remove, update, set } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class RessourceService {
  ressourcesRef: DatabaseReference;
  private dbPath = 'ressources';

  constructor(private database: Database) {
    this.ressourcesRef = ref(this.database, '/ressources');
  }

  getAllRessources(): Observable<Ressource[]> {
    return list(this.ressourcesRef).pipe(
      map(changes => 
        changes
          .map(change => ({
            key: change.snapshot.key,
            ...change.snapshot.val()
          }))
      )
    ) as Observable<Ressource[]>;
  }

  getRessourceById(id: string): Promise<Ressource | null> {
    return get(ref(this.database, `/ressources/${id}`))
      .then(snapshot => {
        if (snapshot.exists()) {
          const ressource = snapshot.val() as Ressource;
          ressource.key = id;
          return ressource;
        }
        return null;
      });
  }

  createRessource(ressource: Ressource) {
    return push(this.ressourcesRef, ressource);
  }

  updateRessource(id: string, ressource: Ressource) {
    return update(ref(this.database, `/ressources/${id}`), ressource);
  }

  deleteRessource(id: string) {
    return remove(ref(this.database, `/ressources/${id}`));
  }
}
