import { Component, inject, ViewChild, ElementRef, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { EvenementService } from '../../../_services/evenement/evenement.service';
import { FirebaseStorageService } from '../../../_services/firebase-storage/firebase-storage.service';
import { Evenement } from '../../../_interfaces/evenement';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-evenement-edition',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  templateUrl: './evenement-edition.component.html',
  styleUrl: './evenement-edition.component.scss'
})
export class EvenementEditionComponent implements OnInit {
  private evenementService: EvenementService = inject(EvenementService);
  private storageService: FirebaseStorageService = inject(FirebaseStorageService);
  evenementForm: FormGroup;
  evenement?: Evenement;
  evenements: Evenement[] = [];
  isModalOpen = false;
  isDeleteModalOpen = false;
  eventToDelete?: Evenement;
  selectedFile?: File;
  imagePreview?: string;

  @ViewChild('editSection') editSection!: ElementRef;

  constructor(private fb: FormBuilder) {
    this.evenementForm = this.fb.group(
      {
        nom: ['', Validators.required],
        description: [''],
        dateDebut: ['', Validators.required],
        dateFin: ['', Validators.required],
        heureDebut: ['', Validators.required],
        heureFin: ['', Validators.required],
        lieu: [''],
        image: [''],
        isPrivate: true
      },
      { 
        validators: this.dateRangeValidator 
      }
    );
  }

  ngOnInit() {
    this.retrieve_evenements();
  }

  toggleDropdown(key: string, isMobile: boolean) {
    const dropdownId = `dropdown-${isMobile ? '' : 'desktop-'}${key}`;
    const buttonId = `dropdownButton-${isMobile ? '' : 'desktop-'}${key}`;
    const dropdown = document.getElementById(dropdownId);
    const button = document.getElementById(buttonId);

    // Ferme tous les autres dropdowns
    document.querySelectorAll('[id^="dropdown-"]').forEach(el => {
      if (el.id !== dropdownId) {
        el.classList.add('hidden');
      }
    });

    if (dropdown && button) {
      const buttonRect = button.getBoundingClientRect();
      const isHidden = dropdown.classList.contains('hidden');

      // Toggle le dropdown
      dropdown.classList.toggle('hidden');

      if (!isHidden) return; // Si on cache le dropdown, pas besoin de calculer la position

      // Position the dropdown relative to the button
      if (window.innerWidth - buttonRect.right < dropdown.offsetWidth) {
        // If there's not enough space on the right, align to the right edge of the button
        dropdown.style.right = '0';
        dropdown.style.left = 'auto';
      } else {
        // Otherwise, align to the left edge of the button
        dropdown.style.left = '0';
        dropdown.style.right = 'auto';
      }
    }
  }

  dateRangeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const formGroup = control as FormGroup;
    const dateDebut = formGroup.get('dateDebut')?.value;
    const dateFin = formGroup.get('dateFin')?.value;
    return dateDebut && dateFin && new Date(dateDebut) > new Date(dateFin) ? { 'dateRangeInvalid': true } : null;
  };

  openModal() {
    this.isModalOpen = true;
    this.onReset();
  }

  closeModal() {
    this.isModalOpen = false;
    this.resetForm();
  }

  async onSubmit() {
    if (this.evenementForm.valid) {
      const dateDebutValue = this.evenementForm.get('dateDebut')?.value;
      const heureDebutValue = this.evenementForm.get('heureDebut')?.value;

      const dateDebut = new Date(dateDebutValue);
      const [heure, minute] = heureDebutValue.split(':');
      dateDebut.setHours(parseInt(heure, 10), parseInt(minute, 10));

      const dateFinValue = this.evenementForm.get('dateFin')?.value;
      const heureFinValue = this.evenementForm.get('heureFin')?.value;

      const dateFin = new Date(dateFinValue);
      const [heureFin, minuteFin] = heureFinValue.split(':');
      dateFin.setHours(parseInt(heureFin, 10), parseInt(minuteFin, 10));

      let imageUrl = this.evenementForm.get('image')?.value;

      // Upload de l'image si une nouvelle image a été sélectionnée
      if (this.selectedFile) {
        const fileName = `${Date.now()}_${this.selectedFile.name}`;
        const path = `evenements/${fileName}`;
        try {
          imageUrl = await this.storageService.uploadFIle(this.selectedFile, path);
        } catch (error) {
          console.error('Erreur lors de l\'upload de l\'image:', error);
          return;
        }
      }

      const evenement: Evenement = {
        nom: this.evenementForm.get('nom')?.value,
        description: this.evenementForm.get('description')?.value,
        dateDebut: dateDebut.toString(),
        dateFin: dateFin.toString(),
        lieu: this.evenementForm.get('lieu')?.value,
        image: imageUrl,
        private: this.evenementForm.get('isPrivate')?.value,
        key: null
      };

      if (this.evenement && this.evenement.key) {
        // Si on modifie un événement et qu'il y a une nouvelle image, on supprime l'ancienne
        if (this.selectedFile && this.evenement.image) {
          try {
            const oldImagePath = this.extractPathFromUrl(this.evenement.image);
            if (oldImagePath) {
              await this.storageService.deleteFile(oldImagePath);
            }
          } catch (error) {
            console.error('Erreur lors de la suppression de l\'ancienne image:', error);
          }
        }

        this.evenementService.updateEvenement(this.evenement.key, evenement, this.evenement.private).then(
          () => {
            this.resetForm();
            this.closeModal();
          },
          error => {
            console.error('Une erreur est survenue :', error);
          }
        );
      } else {
        this.evenementService.createEvenement(evenement, evenement.private).then(
          () => {
            this.resetForm();
            this.closeModal();
          },
          error => {
            console.error('Une erreur est survenue :', error);
          }
        );
      }
    }
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      this.selectedFile = input.files[0];
      
      // Création de l'URL de prévisualisation
      const reader = new FileReader();
      reader.onload = () => {
        this.imagePreview = reader.result as string;
      };
      reader.readAsDataURL(this.selectedFile);
    }
  }

  private extractPathFromUrl(url: string): string | null {
    try {
      const storageUrl = new URL(url);
      const pathMatch = storageUrl.pathname.match(/\/o\/(.+?)\?/);
      if (pathMatch && pathMatch[1]) {
        return decodeURIComponent(pathMatch[1]);
      }
      return null;
    } catch {
      return null;
    }
  }

  private resetForm() {
    this.evenementForm.reset({
      isPrivate: true
    });
    this.evenement = undefined;
    this.selectedFile = undefined;
    this.imagePreview = undefined;
  }

  onEdit(evenement: Evenement) {
    this.evenement = evenement;
    const dateDebutValue = new Date(evenement.dateDebut);
    const dateFinValue = new Date(evenement.dateFin);

    this.evenementForm.patchValue({
      nom: evenement.nom,
      description: evenement.description,
      dateDebut: dateDebutValue.toISOString().split('T')[0],
      heureDebut: ((dateDebutValue.getHours() < 10) ? '0' + dateDebutValue.getHours() : dateDebutValue.getHours()) + ':' + ((dateDebutValue.getMinutes() < 10) ? '0' + dateDebutValue.getMinutes() : dateDebutValue.getMinutes()),
      dateFin: dateFinValue.toISOString().split('T')[0],
      heureFin: ((dateFinValue.getHours() < 10) ? '0' + dateFinValue.getHours() : dateFinValue.getHours()) + ':' + ((dateFinValue.getMinutes() < 10) ? '0' + dateFinValue.getMinutes() : dateFinValue.getMinutes()),
      lieu: evenement.lieu,
      image: evenement.image,
      isPrivate: evenement.private
    });
    this.isModalOpen = true;
  }

  scrollToEditSection() {
    this.editSection.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  onReset() {
    this.evenementForm.reset({
      isPrivate: true
    });
    this.evenement = undefined;
  }

  openDeleteModal(evenement: Evenement) {
    this.eventToDelete = evenement;
    this.isDeleteModalOpen = true;
  }

  closeDeleteModal() {
    this.isDeleteModalOpen = false;
    this.eventToDelete = undefined;
  }

  confirmDelete() {
    if (this.eventToDelete?.key) {
      this.evenementService.deleteEvenement(this.eventToDelete).then(
        () => {
          this.closeDeleteModal();
        },
        error => {
          console.error('Une erreur est survenue :', error);
        }
      );
    }
  }

  onDelete(evenement: Evenement) {
    this.openDeleteModal(evenement);
  }

  retrieve_evenements() {
    this.evenementService.readAllFutureEvenements().subscribe(evenements => {
      this.evenements = evenements;
    });
  }
}
