import { Component, Input, OnInit, inject, input } from '@angular/core';
import { Evenement } from '../../_interfaces/evenement';
import { TruncatePipe } from '../../_pipes/truncate/truncate.pipe';
import { CommonModule } from '@angular/common';
import { Inscription } from '../../_interfaces/inscription';
import { InscriptionService } from '../../_services/inscription/inscription.service';
import { Observable } from 'rxjs';
import { get } from 'http';
import { AuthService } from '../../_services/auth/auth.service';

@Component({
  selector: 'app-evenement-card',
  standalone: true,
  imports: [TruncatePipe, CommonModule],
  templateUrl: './evenement-card.component.html',
  styleUrl: './evenement-card.component.scss'
})
export class EvenementCardComponent implements OnInit{
  @Input() evenement!: Evenement;
  @Input() isPrivate: boolean = false;
  inscriptionService : InscriptionService = inject(InscriptionService);
  authService: AuthService = inject(AuthService);
  inscription: Inscription | null = null;
  sameDate = false;

  ngOnInit(){
    if(this.evenement.key != null && this.isPrivate){
      this.getInscription(this.evenement.key);
    }

    const dateDebutDate = new Date(this.evenement.dateDebut);
    const dateFinDate = new Date(this.evenement.dateFin);

    const dateDebutString = (dateDebutDate.getDate() + "/" + dateDebutDate.getMonth() + "/" + dateDebutDate.getFullYear());
    const dateFinString = (dateFinDate.getDate() + "/" + dateFinDate.getMonth() + "/" + dateFinDate.getFullYear());
    this.sameDate = dateDebutString == dateFinString;
  }

  setInscription(evenement_key: string, is_present: boolean){
    this.inscriptionService.createInscription("", evenement_key, is_present).then((inscription) => {
      this.inscription = inscription;
    });
  }

  getInscription(evenement_key: string) {
    this.inscriptionService.readOneInscriptionOfMeAndEvenement(evenement_key)
      .then(inscriptionFound => {
        if (inscriptionFound != undefined){
          this.inscription = inscriptionFound;
        }
      })
      .catch(error => {
        // Gérer l'erreur ici
        console.error('Erreur lors de la récupération de l\'inscription:', error);
      });
  }
}
