import { Component, inject } from '@angular/core';
import { EvenementService } from '../../_services/evenement/evenement.service';
import { Evenement } from '../../_interfaces/evenement';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { EvenementCardComponent } from '../../_components/evenement-card/evenement-card.component';

@Component({
  selector: 'app-evenements',
  standalone: true,
  imports: [CommonModule, RouterModule, EvenementCardComponent],
  templateUrl: './evenements.component.html',
  styleUrls: ['./evenements.component.scss']
})
export class EvenementsComponent {
  evenementService = inject(EvenementService);
  evenements: Evenement[] = [];

  constructor() {
    this.readEvenements();
  }

  readEvenements(){
    this.evenementService.readAllFuturePublicEvenements().subscribe(
      evenements => {
        this.evenements = evenements
      },
      error => {
        console.error('Une erreur est survenue :', error);
      }
    );
  }
} 