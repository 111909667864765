import { Injectable } from '@angular/core';
import { Database, DatabaseReference, ref, push, remove, update, get, query, orderByChild, list } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Actualite } from '../../_interfaces/actualite';

@Injectable({
  providedIn: 'root'
})
export class ActualiteService {
  actualitesRef: DatabaseReference;

  constructor(private database: Database) {
    this.actualitesRef = ref(this.database, '/actualites');
  }

  // Pour la page d'accueil et la liste publique des actualités
  readPublishedActualites(): Observable<Actualite[]> {
    return list(this.actualitesRef).pipe(
      map(changes => 
        changes
          .map(change => ({
            key: change.snapshot.key,
            ...change.snapshot.val()
          }))
          .filter(actualite => new Date(actualite.date) <= new Date())
          .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
      )
    ) as Observable<Actualite[]>;
  }

  // Pour l'administration - récupère toutes les actualités
  readAllActualites(): Observable<Actualite[]> {
    return list(this.actualitesRef).pipe(
      map(changes => 
        changes
          .map(change => ({
            key: change.snapshot.key,
            ...change.snapshot.val()
          }))
          .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
      )
    ) as Observable<Actualite[]>;
  }

  // Méthodes existantes...
  createActualite(actualite: Actualite) {
    return push(this.actualitesRef, actualite);
  }

  updateActualite(id: string, actualite: Actualite) {
    return update(ref(this.database, `/actualites/${id}`), actualite);
  }

  deleteActualite(id: string) {
    return remove(ref(this.database, `/actualites/${id}`));
  }

  readOneActualite(id: string): Promise<Actualite | null> {
    return get(ref(this.database, `/actualites/${id}`))
      .then(snapshot => {
        if (snapshot.exists()) {
          const actualite = snapshot.val() as Actualite;
          actualite.key = id;
          return actualite;
        }
        return null;
      });
  }
}
