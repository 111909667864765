import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { RessourceService } from '../../_services/ressource/ressource.service';
import { Ressource } from '../../_interfaces/ressource';
import { RessourceType } from '../../_enums/ressource_type.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ressources',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    FormsModule
  ],
  templateUrl: './ressources.component.html',
  styleUrl: './ressources.component.scss'
})
export class RessourcesComponent implements OnInit {
  ressources: Ressource[] = [];
  filteredRessources: Ressource[] = [];
  RessourceType = RessourceType;

  constructor(
    private ressourceService: RessourceService,
    private router: Router
  ) {}

  ngOnInit() {
    this.loadRessources();
  }

  loadRessources() {
    this.ressourceService.getAllRessources().subscribe(ressources => {
      this.ressources = ressources;
      this.filteredRessources = ressources;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.filteredRessources = this.ressources.filter(ressource => 
      ressource.nom.toLowerCase().includes(filterValue) ||
      ressource.type.toLowerCase().includes(filterValue) ||
      (ressource.description && ressource.description.toLowerCase().includes(filterValue))
    );
  }

  viewDetails(ressourceId: string | null | undefined) {
    if (ressourceId) {
      this.router.navigate(['/ressources', ressourceId]);
    }
  }
}
