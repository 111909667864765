<div class="h-[50vh] bg-gradient-to-b from-blue-900 to-white p-0">
    <div class="relative h-full bg-photo-principale bg-center bg-cover flex flex-col">
        <nav class="absolute z-20 bg-gradient-to-b from-gray-900 to-transparent w-full">
            <div class="max-w-screen-xl flex flex-wrap items-center justify-items-end mx-auto p-4">
                <button data-collapse-toggle="navbar-default" type="button" class="m-auto inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-white rounded-lg md:hidden focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
                    <span class="sr-only">Open main menu</span>
                    <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
                    </svg>
                </button>
                <div class="hidden w-full md:block md:w-auto m-auto bg-blue-700 bg-opacity-35 mt-2 rounded-xl text-center md:bg-transparent" id="navbar-default">
                    <ul class="font-medium flex flex-col p-4 md:p-0 mt-4 rounded-lg md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0">
                        <li>
                            <a href="/" class="uppercase text-lg block py-2 px-3 text-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-600 md:p-0">
                                Accueil
                            </a>
                        </li>
                        <li>
                            <a href="/a-propos" class="uppercase text-lg block py-2 px-3 text-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-600 md:p-0">
                                A propos
                            </a>
                        </li>
                        <!-- <li>
                            <a class="uppercase text-lg block py-2 px-3 text-gray-100 opacity-50 md:hover:bg-transparent md:border-0 md:p-0">
                                Actualités
                            </a>
                        </li> -->
                        <!-- <li>
                            <a class="uppercase text-lg block py-2 px-3 text-gray-100 opacity-50 md:hover:bg-transparent md:border-0 md:p-0">
                                Boutique
                            </a>
                        </li> -->
                        <li [hidden]="!isAuth">
                        <!-- <li> -->
                            <button id="dropdownNavbarLink" data-dropdown-toggle="dropdownNavbar" class="m-auto flex flex-row items-center uppercase text-lg py-2 px-3 text-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-600 md:p-0">Membres <svg class="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                            </svg></button>
                            <!-- Dropdown menu -->
                            <div id="dropdownNavbar" class="z-10 hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                                <ul class="py-2 text-sm text-gray-700 dark:text-gray-400" aria-labelledby="dropdownLargeButton">
                                    <li>
                                        <a class="block px-4 py-2 text-gray-200">Mon compte</a>
                                    </li>
                                    <li>
                                        <a href="/agenda" class="block px-4 py-2 hover:bg-gray-100">Agenda</a>
                                    </li>
                                    <li>
                                        <a href="/ressources" class="block px-4 py-2 hover:bg-gray-100">Ressources</a>
                                    </li>
                                    <li [hidden]="!isAdmin">
                                        <a href="/admin/" class="block px-4 py-2 hover:bg-gray-100">Administration</a>
                                    </li>
                                </ul>
                                <div class="py-1 text-sm">
                                    <a (click)="logout()"  class="cursor-pointer block px-4 py-2 hover:bg-gray-100">Déconnexion</a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <div class="absolute z-10 h-[50vh] w-full p-5 pt-16 md:p-20">
            <img alt="Logo de la Bourrée de Paris" src="assets/images/logo.png" class="m-auto items-center h-full object-contain">
        </div>
    </div>
</div>