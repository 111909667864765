import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-auth-layout',
  standalone: true,
  imports: [RouterOutlet],
  template: `
    <div class="min-h-screen flex items-center justify-center">
      <router-outlet></router-outlet>
    </div>
  `,
  styles: ``
})
export class AuthLayoutComponent {} 