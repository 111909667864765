import { Component, inject } from '@angular/core';
import { EvenementService } from '../../_services/evenement/evenement.service';
import { Evenement } from '../../_interfaces/evenement';
import { UtilisateurService } from '../../_services/utilisateur/utilisateur.service';
import { CommonModule } from '@angular/common';
import { TruncatePipe } from '../../_pipes/truncate/truncate.pipe';
import { InscriptionService } from '../../_services/inscription/inscription.service';
import { EvenementCardComponent } from '../../_components/evenement-card/evenement-card.component';

@Component({
  selector: 'app-agenda',
  standalone: true,
  imports: [CommonModule, TruncatePipe, EvenementCardComponent],
  templateUrl: './agenda.component.html',
  styleUrl: './agenda.component.scss'
})
export class AgendaComponent {
  evenementService: EvenementService = inject(EvenementService);
  utilisateurService: UtilisateurService = inject(UtilisateurService);
  inscriptionService: InscriptionService = inject(InscriptionService);

  evenements: Evenement[] = [];

  constructor() {
    this.readEvenements();
  }

  readEvenements(){
    this.evenementService.readAllFutureEvenements().subscribe(
      evenements => {
        this.evenements = evenements
      },
      error => {
        console.error('Une erreur est survenue :', error);
      }
    );
  }
}
