<section class="flex flex-col gap-6 p-4">
  <div class="w-full py-10 px-4 bg-gradient-to-r from-blue-100 via-purple-100 to-pink-100 rounded-lg">
    <h2 class="text-center text-4xl font-alice font-extrabold">Evenements</h2>
  </div>

  <!-- Add Event Button -->
  <div class="flex justify-center md:justify-end">
    <button (click)="openModal()" type="button" class="text-white bg-blue-600 hover:bg-blue-700 font-medium rounded-lg text-sm px-5 py-2.5">
      <span class="flex items-center gap-2">
        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14m-7-7v14"/>
        </svg>
        Ajouter un événement
      </span>
    </button>
  </div>

  <div class="w-full bg-white rounded-md border border-neutral-300">
    <div class="min-w-full">
      <table class="w-full text-sm text-left text-gray-500 font-raleway">
        <tbody>
          <tr *ngFor="let evenement of evenements" class="bg-transparent border-b hover:bg-gray-50 hover:bg-opacity-50">
            <td class="px-6 py-4 font-medium text-gray-900">
              <div class="flex flex-col md:flex-row justify-between items-center gap-2">
                <div class="flex flex-col md:flex-row md:items-center gap-2">
                  <div class="flex flex-row gap-2">
                    <div class="flex items-center gap-2">
                      <svg *ngIf="evenement.private" class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14v3m-3-6V7a3 3 0 1 1 6 0v4m-8 0h10c.6 0 1 .4 1 1v7c0 .6-.4 1-1 1H7c-.6 0-1-.4-1-1v-7c0-.6.4-1 1-1Z"/>
                      </svg>
                      <span class="font-bold">{{ evenement.nom }}</span>
                    </div>
                    <span class="text-sm font-light text-gray-500">{{ evenement.lieu }}</span>
                  </div>
                </div>
                <div class="flex gap-2 justify-end">
                  <div class="relative">
                    <button type="button" [id]="'dropdownButton-desktop-' + (evenement.key ?? '')" (click)="toggleDropdown(evenement.key ?? '', false)" class="text-gray-700 border border-gray-300 bg-white hover:bg-gray-100 focus:ring-1 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 inline-flex items-center relative">
                      Actions
                      <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                      </svg>
                    </button>
                    <div [id]="'dropdown-desktop-' + (evenement.key ?? '')" class="z-[9999] hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 absolute mt-2" style="right: 0;">
                      <ul class="py-2 text-sm text-gray-700" [attr.aria-labelledby]="'dropdownButton-desktop-' + (evenement.key ?? '')">
                        <li>
                          <a href="/agenda/{{ evenement.key }}" target="_blank" class="flex items-center px-4 py-2 hover:bg-gray-100">
                            <svg class="w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"/>
                            </svg>
                            Consulter
                          </a>
                        </li>
                        <li>
                          <button (click)="onEdit(evenement)" class="flex items-center w-full px-4 py-2 hover:bg-gray-100">
                            <svg class="w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m14.3 4.8 2.9 2.9M7 7H4a1 1 0 0 0-1 1v10c0 .6.4 1 1 1h11c.6 0 1-.4 1-1v-4.5m2.4-10a2 2 0 0 1 0 3l-6.8 6.8L8 14l.7-3.6 6.8-6.8a2 2 0 0 1 2.9 0Z"/>
                            </svg>
                            Modifier
                          </button>
                        </li>
                        <li>
                          <button (click)="onDelete(evenement)" class="flex items-center w-full px-4 py-2 hover:bg-gray-100 text-red-600">
                            <svg class="w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"/>
                            </svg>
                            Supprimer
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  
  <!-- Modal -->
  <div [id]="'eventModal'" [class.hidden]="!isModalOpen" class="fixed inset-0 z-50 overflow-y-auto">
    <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <!-- Background overlay -->
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <!-- Modal panel -->
      <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="flex justify-between items-center mb-4">
            <h3 class="text-lg font-medium leading-6 text-gray-900">{{ evenement ? 'Modifier' : 'Ajouter' }} un événement</h3>
            <button (click)="closeModal()" type="button" class="text-gray-400 hover:text-gray-500">
              <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          
          <div #editSection>
            <form [formGroup]="evenementForm" (ngSubmit)="onSubmit()">
              <div class="space-y-6">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div class="col-span-1 md:col-span-2">
                    <label for="nom" class="block text-sm font-semibold text-gray-700 font-raleway">Nom de l'événement</label>
                    <input type="text" id="nom" formControlName="nom" 
                      class="mt-1 block w-full px-3 py-2 bg-transparent border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 font-raleway
                      focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500">
                  </div>

                  <div class="col-span-1 md:col-span-2">
                    <label for="description" class="block text-sm font-semibold text-gray-700 font-raleway">Description</label>
                    <textarea id="description" formControlName="description" rows="4"
                      class="mt-1 block w-full px-3 py-2 bg-transparent border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 font-raleway
                      focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"></textarea>
                  </div>

                  <div class="col-span-1">
                    <label for="dateDebut" class="block text-sm font-semibold text-gray-700 font-raleway">Date de début</label>
                    <input type="date" id="dateDebut" formControlName="dateDebut"
                      class="mt-1 block w-full px-3 py-2 bg-transparent border border-gray-300 rounded-md text-sm shadow-sm font-raleway
                      focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500">
                  </div>

                  <div class="col-span-1">
                    <label for="heureDebut" class="block text-sm font-semibold text-gray-700 font-raleway">Heure de début</label>
                    <input type="time" id="heureDebut" formControlName="heureDebut"
                      class="mt-1 block w-full px-3 py-2 bg-transparent border border-gray-300 rounded-md text-sm shadow-sm font-raleway
                      focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500">
                  </div>

                  <div class="col-span-1">
                    <label for="dateFin" class="block text-sm font-semibold text-gray-700 font-raleway">Date de fin</label>
                    <input type="date" id="dateFin" formControlName="dateFin"
                      class="mt-1 block w-full px-3 py-2 bg-transparent border border-gray-300 rounded-md text-sm shadow-sm font-raleway
                      focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500">
                  </div>

                  <div class="col-span-1">
                    <label for="heureFin" class="block text-sm font-semibold text-gray-700 font-raleway">Heure de fin</label>
                    <input type="time" id="heureFin" formControlName="heureFin"
                      class="mt-1 block w-full px-3 py-2 bg-transparent border border-gray-300 rounded-md text-sm shadow-sm font-raleway
                      focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500">
                  </div>

                  <div class="col-span-1 md:col-span-2">
                    <label for="lieu" class="block text-sm font-semibold text-gray-700 font-raleway">Lieu</label>
                    <input type="text" id="lieu" formControlName="lieu"
                      class="mt-1 block w-full px-3 py-2 bg-transparent border border-gray-300 rounded-md text-sm shadow-sm font-raleway
                      focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500">
                  </div>

                  <div class="col-span-1 md:col-span-2">
                    <label for="image" class="block text-sm font-semibold text-gray-700 font-raleway">Image</label>
                    <div class="flex flex-col gap-4">
                      <input type="file" id="image" (change)="onFileSelected($event)" accept="image/*"
                        class="mt-1 block w-full px-3 py-2 bg-transparent border border-gray-300 rounded-md text-sm shadow-sm font-raleway
                        focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500">
                      <img *ngIf="imagePreview || evenementForm.get('image')?.value" [src]="imagePreview || evenementForm.get('image')?.value" 
                        class="max-w-xs rounded-lg shadow-md" alt="Prévisualisation de l'image">
                    </div>
                  </div>

                  <div class="col-span-1 md:col-span-2">
                    <label class="flex items-center space-x-3">
                      <input type="checkbox" id="isPrivate" formControlName="isPrivate"
                        class="h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500">
                      <span class="text-sm font-medium text-gray-700 font-raleway">Événement Privé</span>
                    </label>
                  </div>
                </div>

                <div class="flex flex-col gap-4 sm:flex-row pt-4">
                  <button type="submit"
                    class="w-full bg-blue-600 text-white px-6 py-3 rounded-md text-sm font-medium hover:bg-blue-700 font-raleway
                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors">
                    Enregistrer
                  </button>
                  <button type="button" (click)="closeModal()"
                    class="w-full bg-transparent text-red-600 px-6 py-3 rounded-md text-sm font-medium border-2 border-red-600 font-raleway
                    hover:bg-red-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-colors">
                    Annuler
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Delete Confirmation Modal -->
  <div [id]="'deleteModal'" [class.hidden]="!isDeleteModalOpen" class="fixed inset-0 z-50 overflow-y-auto">
    <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <!-- Background overlay -->
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <!-- Modal panel -->
      <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <svg class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
              </svg>
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Supprimer l'événement
              </h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">
                  Êtes-vous sûr de vouloir supprimer l'événement "{{ eventToDelete?.nom }}" ? Cette action est irréversible.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button (click)="confirmDelete()" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
            Supprimer
          </button>
          <button (click)="closeDeleteModal()" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
            Annuler
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
