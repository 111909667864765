import { Component, inject, ViewChild, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActualiteService } from '../../../_services/actualite/actualite.service';
import { FirebaseStorageService } from '../../../_services/firebase-storage/firebase-storage.service';
import { Actualite } from '../../../_interfaces/actualite';
import { QuillModule, QuillEditorComponent } from 'ngx-quill';
import { Router } from '@angular/router';
import { TagType, TAG_CONFIG } from '../../../_enums/tag.enum';

@Component({
  selector: 'app-actualite-edition',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, QuillModule],
  templateUrl: './actualite-edition.component.html'
})
export class ActualiteEditionComponent implements AfterViewInit {
  private actualiteService = inject(ActualiteService);
  private firebaseStorageService = inject(FirebaseStorageService);
  private fb = inject(FormBuilder);
  private router = inject(Router);

  @ViewChild('editor') editor!: QuillEditorComponent;
  quillInstance: any;

  actualiteForm: FormGroup;
  actualite?: Actualite;
  actualites: Actualite[] = [];
  imageFile?: File;
  isModalOpen = false;
  isDeleteModalOpen = false;
  actualiteToDelete?: Actualite;

  private readonly MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
  private readonly MAX_WIDTH = 1200;
  private readonly MAX_HEIGHT = 800;
  private readonly QUALITY = 0.7;

  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ 'header': 1 }, { 'header': 2 }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      [{ 'direction': 'rtl' }],
      [{ 'align': [] }],
      ['link', 'image'],
      ['clean']
    ],
    imageResize: {
      displaySize: true,
      modules: ['Resize', 'DisplaySize', 'Toolbar']
    }
  };

  quillConfig = {
    toolbar: '.toolbar',
    autoLink: true,
    imageResize: true
  };

  styles = `
    .ql-editor img {
      display: block;
      margin: auto;
      max-width: 100%;
      height: auto;
    }
    .ql-editor .ql-align-center {
      text-align: center;
    }
    .ql-editor .ql-align-right {
      text-align: right;
    }
    .ql-editor .ql-align-left {
      text-align: left;
    }
  `;

  // Variable pour stocker temporairement le fichier image
  selectedImageFile: File | null = null;

  tagTypes = Object.values(TagType);
  tagConfig = TAG_CONFIG;

  constructor() {
    this.actualiteForm = this.fb.group({
      titre: ['', Validators.required],
      contenu: ['', Validators.required],
      auteur: ['', Validators.required],
      imagePreview: [''],
      image: [''],
      date: ['', Validators.required],
      tags: [[]]
    });
    
    this.loadActualites();
  }

  ngAfterViewInit() {
    // Ajoute les styles personnalisés
    const styleElement = document.createElement('style');
    styleElement.textContent = this.styles;
    document.head.appendChild(styleElement);

    this.editor.onEditorCreated.subscribe((quill) => {
      this.quillInstance = quill;
    });
  }

  ngOnInit() {
    // Si on a une actualité à modifier, on initialise le formulaire avec ses données
    if (this.actualite) {
      this.actualiteForm.patchValue({
        titre: this.actualite.titre,
        contenu: this.actualite.contenu,
        auteur: this.actualite.auteur || '',
        image: this.actualite.image || '',
        date: new Date(this.actualite.date).toISOString().split('T')[0],
        tags: this.actualite.tags || []
      });
    }
  }

  private async resizeImage(file: File): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target?.result as string;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          if (width > this.MAX_WIDTH) {
            height = Math.round(height * (this.MAX_WIDTH / width));
            width = this.MAX_WIDTH;
          }
          if (height > this.MAX_HEIGHT) {
            width = Math.round(width * (this.MAX_HEIGHT / height));
            height = this.MAX_HEIGHT;
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx?.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              if (blob) {
                resolve(blob);
              } else {
                reject(new Error('Échec de la conversion en blob'));
              }
            },
            'image/jpeg',
            this.QUALITY
          );
        };
      };
      reader.onerror = (error) => reject(error);
    });
  }

  async processImageUpload(file: File): Promise<string> {
    if (file.size > this.MAX_FILE_SIZE) {
      throw new Error(`L'image est trop grande. Taille maximum: ${this.MAX_FILE_SIZE / (1024 * 1024)}MB`);
    }

    try {
      const resizedImage = await this.resizeImage(file);
      const path = `actualites/content/${Date.now()}_${file.name}`;
      return await this.firebaseStorageService.uploadFIle(
        new File([resizedImage], file.name, { type: 'image/jpeg' }), 
        path
      );
    } catch (error) {
      console.error('Erreur lors du traitement de l\'image:', error);
      throw error;
    }
  }

  imageHandler() {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      if (input.files && input.files[0] && this.quillInstance) {
        try {
          const url = await this.processImageUpload(input.files[0]);
          const range = this.quillInstance.getSelection(true);
          this.quillInstance.insertEmbed(range.index, 'image', url);
          this.quillInstance.setSelection(range.index + 1);
        } catch (error) {
          alert(error instanceof Error ? error.message : 'Erreur lors de l\'upload de l\'image');
        }
      }
    };
  }

  loadActualites() {
    this.actualiteService.readAllActualites().subscribe(
      actualites => this.actualites = actualites
    );
  }

  async onSubmit() {
    if (this.actualiteForm.valid) {
      try {
        let imageUrl = this.actualiteForm.get('image')?.value;
        
        if (this.selectedImageFile) {
          const resizedImage = await this.resizeImage(this.selectedImageFile);
          const path = `actualites/covers/${Date.now()}_${this.selectedImageFile.name}`;
          imageUrl = await this.firebaseStorageService.uploadFIle(
            new File([resizedImage], this.selectedImageFile.name, { type: 'image/jpeg' }), 
            path
          );
        } else if (this.actualite?.image) {
          imageUrl = this.actualite.image;
        }

        // Récupération et formatage de la date
        const formDate = this.actualiteForm.get('date')?.value;
        console.log(formDate);
        const selectedDate = new Date(formDate);
        selectedDate.setHours(12, 0, 0, 0); // Midi pour éviter les problèmes de fuseau horaire

        const actualiteData: Actualite = {
          titre: this.actualiteForm.get('titre')?.value || '',
          contenu: this.actualiteForm.get('contenu')?.value || '',
          auteur: this.actualiteForm.get('auteur')?.value || '',
          date: selectedDate.toISOString(),
          image: imageUrl,
          tags: this.actualiteForm.get('tags')?.value || []
        };

        console.log(actualiteData);

        if (this.actualite?.key) {
          await this.actualiteService.updateActualite(this.actualite.key, actualiteData);
        } else {
          await this.actualiteService.createActualite(actualiteData);
        }

        this.selectedImageFile = null;
        this.actualiteForm.reset();
        this.router.navigate(['/admin/actualites']);
      } catch (error) {
        console.error('Erreur lors de la soumission:', error);
        alert('Erreur lors de la sauvegarde de l\'actualité');
      }
    }
  }

  openModal() {
    this.actualite = undefined;
    this.actualiteForm.reset();
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
    this.actualite = undefined;
    this.actualiteForm.reset();
  }

  onEdit(actualite: Actualite) {
    this.actualite = actualite;
    this.actualiteForm.patchValue({
      titre: actualite.titre,
      contenu: actualite.contenu,
      auteur: actualite.auteur || '',
      image: actualite.image || '',
      date: new Date(actualite.date).toISOString().split('T')[0],
      tags: actualite.tags || []
    });
    this.isModalOpen = true;
  }

  onDelete(actualite: Actualite) {
    this.actualiteToDelete = actualite;
    this.isDeleteModalOpen = true;
  }

  closeDeleteModal() {
    this.isDeleteModalOpen = false;
    this.actualiteToDelete = undefined;
  }

  async confirmDelete() {
    if (this.actualiteToDelete?.key) {
      try {
        await this.actualiteService.deleteActualite(this.actualiteToDelete.key);
        this.loadActualites();
        this.closeDeleteModal();
      } catch (error) {
        console.error('Erreur lors de la suppression:', error);
      }
    }
  }

  toggleDropdown(key: string, isMobile: boolean) {
    const dropdownId = `dropdown-${isMobile ? '' : 'desktop-'}${key}`;
    const buttonId = `dropdownButton-${isMobile ? '' : 'desktop-'}${key}`;
    const dropdown = document.getElementById(dropdownId);
    const button = document.getElementById(buttonId);

    // Ferme tous les autres dropdowns
    document.querySelectorAll('[id^="dropdown-"]').forEach(el => {
      if (el.id !== dropdownId) {
        el.classList.add('hidden');
      }
    });

    if (dropdown && button) {
      const buttonRect = button.getBoundingClientRect();
      const isHidden = dropdown.classList.contains('hidden');

      // Toggle le dropdown
      dropdown.classList.toggle('hidden');

      if (!isHidden) return; // Si on cache le dropdown, pas besoin de calculer la position

      // Position the dropdown relative to the button
      if (window.innerWidth - buttonRect.right < dropdown.offsetWidth) {
        // If there's not enough space on the right, align to the right edge of the button
        dropdown.style.right = '0';
        dropdown.style.left = 'auto';
      } else {
        // Otherwise, align to the left edge of the button
        dropdown.style.left = '0';
        dropdown.style.right = 'auto';
      }
    }
  }

  onReset() {
    this.actualite = undefined;
    this.imageFile = undefined;
    this.actualiteForm.reset();
  }

  onImageSelected(event: any) {
    const file = event.target.files[0];
    if (!file) return;

    if (file.size > this.MAX_FILE_SIZE) {
      alert(`L'image est trop grande. Taille maximum: ${this.MAX_FILE_SIZE / (1024 * 1024)}MB`);
      return;
    }

    // Stocke le fichier pour l'upload ultérieur
    this.selectedImageFile = file;

    // Affiche uniquement la prévisualisation
    const reader = new FileReader();
    reader.onload = (e) => {
      this.actualiteForm.patchValue({
        imagePreview: e.target?.result
      });
    };
    reader.readAsDataURL(file);
  }

  toggleTag(tag: TagType) {
    const currentTags = this.actualiteForm.get('tags')?.value || [];
    const index = currentTags.indexOf(tag);
    
    if (index === -1) {
      this.actualiteForm.patchValue({
        tags: [...currentTags, tag]
      });
    } else {
      currentTags.splice(index, 1);
      this.actualiteForm.patchValue({
        tags: currentTags
      });
    }
  }

  isTagSelected(tag: TagType): boolean {
    const currentTags = this.actualiteForm.get('tags')?.value || [];
    return currentTags.includes(tag);
  }
}
