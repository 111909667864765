import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AdminNavbarComponent } from '../../_components/admin-navbar/admin-navbar.component';

@Component({
  selector: 'app-admin-layout',
  standalone: true,
  imports: [RouterOutlet, AdminNavbarComponent],
  template: `
    <app-admin-navbar></app-admin-navbar>
    <div class="p-4 sm:ml-64">
      <router-outlet></router-outlet>
    </div>
  `,
  styles: ``
})
export class AdminLayoutComponent {} 