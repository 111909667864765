export enum RessourceType {
  DANSE = 'DANSE',
  MUSIQUE = 'MUSIQUE',
  CHANT = 'CHANT',
  SPECTACLE = 'SPECTACLE',
}

export enum RessourceFileType {
  IMAGE = 'IMAGE',
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO',
  PDF = 'PDF',
  YOUTUBE = 'YOUTUBE',
  UNKNOWN = 'UNKNOWN',
}