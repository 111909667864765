<article class="container py-8">
    @if(actualite) {
    <div class="text-center my-10">
        <span class="text-gray-600">{{ actualite.date | date:'shortDate':'':'fr' }}</span>
        <h1 class="pb-2 text-center text-4xl lg:text-6xl text-blue-dark font-alice font-medium">{{ actualite.titre }}</h1>
        @if(actualite.auteur) {
            <span class="text-gray-600">par <b>{{ actualite.auteur }}</b></span>
        }
        @if(actualite.tags && actualite.tags.length > 0) {
            <div class="p-0 gap-2 text-center">
            @for(tag of actualite.tags; track tag) {
                <span class="px-2 py-0.5 bg-primary/10 rounded-full text-xs text-primary">
                {{ tag }}
                </span>
            }
            </div>
        }
    </div>

    @if(actualite.image) {
    <div class="flex justify-center m-3">
        <img [src]="actualite.image" [alt]="actualite.titre" class="w-full md:w-2/3 h-[200px] md:h-[600px] object-cover rounded-lg m-3 md:mx-auto my-20">
    </div>
    }

    <div class="prose max-w-none mx-auto px-4 md:px-8 lg:px-16">
        <div [innerHTML]="sanitizedContent" class="quill-content"></div>
    </div>
    }
</article>